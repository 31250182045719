import { createStore, combineReducers,applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import authSagas from './sagas/auth'
import { responsiveReducer } from './ducks/responsive'
import { authReducer } from './ducks/auth'

const rootSagas = function* () {
  yield all([
    authSagas()
  ])
}
const sagaMiddleware = createSagaMiddleware()

const reducers = combineReducers({
  sidebarShow: responsiveReducer,
  auth: authReducer
});

const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSagas);

export default store