//Auth Types
export const types = {
    AUTH_REQUEST: "AUTH_REQUEST",
    AUTH_REQUEST_ERROR: "AUTH_REQUEST_ERROR",
    AUTH_REQUEST_SUCCESS: "AUTH_REQUEST_SUCCESS",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_REQUEST_SUCCESS: "LOGOUT_REQUEST_SUCCESS",
    CHECK_SESSION_REQUEST: "CHECK_SESSION_REQUEST",
    CHECK_SESSION_SUCCESS: "CHECK_SESSION_SUCCESS",
    CHECK_SESSION_ERROR: "CHECK_SESSION_ERROR",

}

// Auth actions
export const actions = {
    loginRequest: payload => ({ type: types.AUTH_REQUEST, payload }),
    logoutRequest: () => ({type: types.LOGOUT_REQUEST}),
    checkSessionRequest: () => ({type: types.CHECK_SESSION_REQUEST}),
}

// Auth reducer
let initialState = {
    error: undefined,
    session: undefined,
    fetching: false,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_REQUEST:
            return { ...state, fetching: true, error: undefined};
        case types.AUTH_REQUEST_SUCCESS:
            return { ...state, error: false, fetching: false, session: action.payload }
        case types.AUTH_REQUEST_ERROR:
            return { ...state, error: action.error, fetching: false, session: undefined }
        case types.LOGOUT_REQUEST_SUCCESS:
            return initialState;
        case types.CHECK_SESSION_REQUEST:
            return { ...state, fetching: true, error: undefined};
        case types.CHECK_SESSION_SUCCESS:
            return { ...state, error: false, fetching: false, session: action.payload }
        case types.CHECK_SESSION_ERROR:
            return { ...state, error: undefined, fetching: false, session: undefined }
        default:
            return state;
    }
}