import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../redux/ducks/auth";
import Auth from "./Auth";
import Public from "./Public";

class Navigation extends PureComponent {
	componentDidMount() {
		this.props.checkSessionRequest();
	}

	render() {
		const { auth } = this.props;
		if (auth.session) return <Auth />;
		if (!auth.session) return <Public />;
	}
}

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ checkSessionRequest: actions.checkSessionRequest },
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
