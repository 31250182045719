import axios from "axios";

const createRequestHandler = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL || "http://localhost:3030/api",
  });
  //Checa los metodos de los interceptors, pueden llegar a ser de mucha ayuda xd

  instance?.interceptors?.request?.use((config) => {
    const token = localStorage.getItem("authorization");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export default createRequestHandler;
