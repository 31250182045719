import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Containers
const TheLayout = React.lazy(() => import('../containers/TheLayout'));

// Pages
const Page404 = React.lazy(() => import('../views/pages/Page404'));

const Auth = () => (
  <Switch>
    <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
    <Route path="/404" name="Page 404" render={props => <Page404 {...props} />} />
    <Route component={Page404} />
  </Switch>
)

export default Auth;
