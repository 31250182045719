import { call, put, takeLatest, all } from 'redux-saga/effects';
import createRequestHandler from '../../lib/api';
import { types } from '../../redux/ducks/auth';

function* fetchUser(action) {
    const API = createRequestHandler();
    try {
        const resp = yield call(API.post, '/api/login', action.payload);

        if (resp.data.token) {
            yield put({ type: types.AUTH_REQUEST_SUCCESS, payload: resp.data });
            localStorage.setItem("authorization", resp.data.token)
            localStorage.setItem("userid", resp.data.user.id)
        } else {
            yield put({ type: types.AUTH_REQUEST_ERROR, error: resp.data.message, fetching: false });
        }
    } catch (error) {
        yield put({ type: types.AUTH_REQUEST_ERROR, error: error.response.data });
    }
}

function* logout(action) {
    localStorage.removeItem("authorization");
    localStorage.removeItem("userid");
    yield put({ type: types.LOGOUT_REQUEST_SUCCESS });
}

function* checkSession(action) {
    try {
        if (!localStorage.getItem('authorization') || !localStorage.getItem('userid')) {
            yield put({ type: types.CHECK_SESSION_ERROR });
            return;
        }

        const API = createRequestHandler();
        const resp = yield call(API.get, '/api/checkSession');
        yield put({ type: types.CHECK_SESSION_SUCCESS, payload: resp.data });
    } catch (error) {
        yield put({ type: types.CHECK_SESSION_ERROR });
    }
}

export default function* watchAll() {
    yield all([
        takeLatest(types.AUTH_REQUEST, fetchUser),
        takeLatest(types.LOGOUT_REQUEST, logout),
        takeLatest(types.CHECK_SESSION_REQUEST, checkSession),
    ])
}
