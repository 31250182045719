import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// Pages
const Login = React.lazy(() => import('../views/pages/Login'))
const Register = React.lazy(() => import('../views/pages/Register'))
const FinishRegister = React.lazy(() => import('../views/pages/FinishRegister'))
const ForgotPassword = React.lazy(() => import('../views/pages/ForgotPassword'))
const PasswordReset = React.lazy(() => import('../views/pages/PasswordReset'))
const ResetPasswordFinish = React.lazy(() =>
	import('../views/pages/ResetPasswordFinish')
)
const Page404 = React.lazy(() => import('../views/pages/Page404'))

const Public = () => (
	<Switch>
		<Redirect exact from="/" to="/login" />
		<Route
			exact
			path="/login"
			name="Login Page"
			render={(props) => <Login {...props} />}
		/>
		<Route
			exact
			path="/register"
			name="Register Page"
			render={(props) => <Register {...props} />}
		/>
		<Route
			exact
			path="/finish-register"
			name="Finish Register Page"
			render={(props) => <FinishRegister {...props} />}
		/>
		<Route
			exact
			path="/forgot-password"
			name="Forgot password Page"
			render={(props) => <ForgotPassword {...props} />}
		/>
		<Route
			exact
			path="/password-reset/:userId"
			name="Reset password Page"
			render={(props) => <PasswordReset {...props} />}
		/>
		<Route
			exact
			path="/finish-password"
			name="Finish reset password Page"
			render={(props) => <ResetPasswordFinish {...props} />}
		/>
		<Route
			path="/404"
			name="Page 404"
			render={(props) => <Page404 {...props} />}
		/>
		<Route component={Page404} />
	</Switch>
)

export default Public
