const initialState = 'responsive'

export const responsiveReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return rest.sidebarShow;
    default:
      return state
  }
};
