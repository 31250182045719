import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from "./navigation";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/App.scss";

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const App = () => (
	<Router>
		<React.Suspense fallback={loading}>
			<Navigation />
		</React.Suspense>
	</Router>
);

export default App;
